import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import {
    MatDialogModule, MatDatepickerModule, MatAutocompleteModule, MatButtonModule,
    MatFormFieldModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule,
    MatExpansionModule, MatGridListModule,  MatInputModule, MatListModule,
    MatMenuModule, MatNativeDateModule, MatProgressBarModule, MatProgressSpinnerModule,
    MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule,
    MatSlideToggleModule, MatSnackBarModule, MatTabsModule, MatToolbarModule, MatTooltipModule,
    MatTableModule, MatPaginatorModule, MatSortModule, MatDividerModule, MatIconModule
} from '@angular/material';
import { ScrollingModule } from '@angular/cdk/scrolling';


@NgModule({
    imports: [RouterModule, CommonModule,
        MatDialogModule,
        MatDatepickerModule,
        CommonModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatFormFieldModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatDividerModule,
        ScrollingModule,

    ],
    declarations: [SidebarComponent],
    exports: [SidebarComponent]
})

export class SidebarModule { }
