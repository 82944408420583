import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;

declare interface RouteInfo {
    path: string;
    title: string;
    icon?: string;
    class?: string;
}
 
export const DASHBOARDSECTION: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',icon: 'dashboard' },
    { path: '/master', title: 'Master' ,icon: 'polymer'},
    { path: '/list-class', title: 'Class',icon: 'classes' },
    { path: '/list-sections', title: 'Section',icon: 'receipt' },
];

export const STUDENTSECTION: RouteInfo[] = [
    { path: '/list-students', title: 'List Student' ,icon: 'keyboard_arrow_right'},
    // { path: '/edit-students', title: 'Edit Student' },
    { path: '/add-students', title: 'Add Student',icon: 'dashboard' },
];

export const EVENTSECTION: RouteInfo[] = [
    { path: '/list-events', title: 'List Event' ,icon: 'event_available'},
    // { path: '/edit-students', title: 'Edit Student' },
    { path: '/add-events', title: 'Add Event',icon: 'dashboard' },
];

export const STAFFFSECTION: RouteInfo[] = [
    { path: '/list-staffs', title: 'List Staff',icon: 'dashboard' },
    // { path: '/edit-staffs', title: 'Edit Staff' },
    { path: '/add-staffs', title: 'Add Staff',icon: 'dashboard' },
];

export const SUBJECTSECTION: RouteInfo[] = [
    { path: '/list-subjects', title: 'Subject',icon: 'local_library' },
    // { path: '/edit-subjects', title: 'Edit Subject' },
    // { path: '/add-subjects', title: 'Add Subject' },
];

export const TEACHERSECTION: RouteInfo[] = [
    { path: '/list-teachers', title: 'List Teacher',icon: 'dashboard' },
    // { path: '/edit-teachers', title: 'Edit Teacher' },
    { path: '/add-teachers', title: 'Add Teacher',icon: 'dashboard' },
];

export const SUBJECTTYPESECTION: RouteInfo[] = [
    { path: '/list-subject-type', title: 'Subject Type',icon: 'dashboard' },
]

export const BUSTRACKINGSECTION: RouteInfo[] = [
    { path: '/bus-tracking', title: 'Bus Tracking',icon: 'dashboard' },
];
export const SUBSCRIPTIONGSECTION: RouteInfo[] = [
    { path: '/subscriptions', title: 'Subscription',icon: 'dashboard' },
];
export const SUBJECTALLOSECTION: RouteInfo[] = [
    { path: '/list-subject-allocations', title: 'List Subject Allocation',icon: 'dashboard' },
    { path: '/add-subject-allocations', title: 'Add Subject Allocation',icon: 'dashboard' },
    // { path: '/edit-subject-allocations', title: 'Edit Subject Allocation' },
];
export const SUBALLOSECTION: RouteInfo[] = [
    { path: '/subscription-allocations', title: 'Subscription Allocation',icon: 'dashboard' },
];
export const CALENDERSECTION: RouteInfo[] = [
    { path: '/calender', title: 'Calender',icon: 'dashboard' },
];
export const TIMETABLESECTION: RouteInfo[] = [
    { path: '/time-table', title: 'Time Table',icon: 'timer' },
];
export const MEDIUMSECTION: RouteInfo[] = [
    { path: '/medium', title: 'Medium' ,icon: 'dashboard'},
];
export const ATTENDANCESECTION: RouteInfo[] = [
    { path: '/std-attendances', title: 'Attendance' ,icon: 'dashboard'},
];
export const EXAMSECTION: RouteInfo[] = [
    // { path: '/add-marks', title: 'Marks' },
    // { path: '/mark-status', title: 'Mark Status' },
    // { path: '/create-exam', title: 'Add Exam' },
    //{ path: '/result-publish', title: 'Result Publish',icon: 'dashboard' },
    { path: '/results', title: 'Results',icon: 'dashboard' },
    { path: '/list-exam', title: 'List Exam',icon: 'dashboard' },
];
export const ACTIVITYSECTION: RouteInfo[] = [
    { path: '/list-activity', title: 'List Activity' ,icon: 'assignment'},
    { path: '/add-activity', title: 'Add Activity',icon: 'assignment' }, 
];
export const GALLERYSECTION: RouteInfo[] = [
    { path: '/list-gallery', title: 'List Gallery',icon: 'dashboard' },
    { path: '/add-gallery', title: 'Add Gallery',icon: 'dashboard' },
    
];
export const MATERIALSECTION: RouteInfo[] = [
    { path: '/list-materials', title: 'List Material',icon: 'dashboard' },
    { path: '/add-materials', title: 'Add Material',icon: 'dashboard' },
    
    
];
export const NOTIFICATIONSECTION: RouteInfo[] = [
    { path: '/notifications', title: 'Notification',icon: 'dashboard' },
    { path: '/result-notifications', title: 'Result Notification',icon: 'dashboard' }
];





@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
})

export class SidebarComponent implements OnInit {
    panelOpenState = false;
    dashboardMenuItems = [];
    studentMenuItems = [];
    subjectTypeItems =[];
    subjectMenuItems = [];
    staffMenuItems = [];
    teacherMenuItems = [];
    subscriptionsMenuItems = [];
    busMenuItems = [];
    subAllsMenuItems = [];
    calenderMenuItems = [];
    timeMenuItems = [];
    mediumMenuItems = [];
    examMenuItems = [];
    activityMenuItems = [];
    subjectsAlloMenuItems = [];
    materialMenuItems = [];
    galleryMenuItems = [];
    attendanceMenuItems = [];
    eventsMenuItems = [];
    notificationMenuItems = []; 
    getSideExpansionId = '';

    constructor(
        private router: Router,
    ) { }


    ngOnInit() {

        this.dashboardMenuItems = DASHBOARDSECTION.filter(dashboardMenuItem => dashboardMenuItem);
        this.studentMenuItems = STUDENTSECTION.filter(studentMenuItem => studentMenuItem);
        this.subjectTypeItems = SUBJECTTYPESECTION.filter(subjectTypeMenu => subjectTypeMenu)
        this.subjectMenuItems = SUBJECTSECTION.filter(subjectMenuItem => subjectMenuItem);
        this.staffMenuItems = STAFFFSECTION.filter(staffMenuItem => staffMenuItem);
        this.teacherMenuItems = TEACHERSECTION.filter(teacherMenuItem => teacherMenuItem);
        this.busMenuItems = BUSTRACKINGSECTION.filter(busMenuItem => busMenuItem);
        this.subscriptionsMenuItems = SUBSCRIPTIONGSECTION.filter(subscriptionsMenuItem => subscriptionsMenuItem);
        this.subjectsAlloMenuItems = SUBJECTALLOSECTION.filter(subjectAllsMenuItem => subjectAllsMenuItem);
        this.subAllsMenuItems = SUBALLOSECTION.filter(subAllsMenuItem => subAllsMenuItem);
        this.calenderMenuItems = CALENDERSECTION.filter(calenderMenuItem => calenderMenuItem);
        this.timeMenuItems = TIMETABLESECTION.filter(timeMenuItem => timeMenuItem);
        this.mediumMenuItems = MEDIUMSECTION.filter(mediumMenuItem => mediumMenuItem);
        this.examMenuItems = EXAMSECTION.filter(examMenuItem => examMenuItem);
        this.activityMenuItems = ACTIVITYSECTION.filter(activityMenuItem => activityMenuItem);
        this.materialMenuItems = MATERIALSECTION.filter(materialMenuItem => materialMenuItem);
        this.galleryMenuItems = GALLERYSECTION.filter(galleryMenuItem => galleryMenuItem);
        this.eventsMenuItems = EVENTSECTION.filter(eventsMenuItem => eventsMenuItem);
        this.attendanceMenuItems = ATTENDANCESECTION.filter(attendanceMenuItem => attendanceMenuItem);
        this.notificationMenuItems = NOTIFICATIONSECTION.filter(notificationMenuItem => notificationMenuItem);
         this.getSideExpansionId = localStorage.getItem('sideExpand');

    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    setExpand(id) {
        localStorage.setItem('sideExpand', id);
    }

    getStudentSide(id) {
        return this.getExpand(id);
    }
    getSubjectSide(id) {
        return this.getExpand(id);
    }
    getTeacherSide(id) {
        return this.getExpand(id);
    }
    getSubjectAllSide(id) {
        return this.getExpand(id);
    }
    getExamSide(id) {
        return this.getExpand(id);
    }
    getEventSide(id) {
        return this.getExpand(id);
    }
    getNotificationSide(id) {
        return this.getExpand(id);
    }


    getExpand(id) {
        if (id == this.getSideExpansionId) {
            return true;
        } else {
            return false;
        }
    }
}
