import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { TokenService } from './token.service';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})

export class AfterLoginService implements CanActivate{

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    
    if (this.tokenService.loggedIn())  {
      return true;
    }
    this.router.navigateByUrl('login');
  }

  constructor(
    private tokenService: TokenService,
    private breakpointObserver: BreakpointObserver,
    private router : Router
  ) { }
}
