import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = environment.apiUrl;
  private loggedIn = new BehaviorSubject<boolean>(this.tokenService.loggedIn());

  authStatus = this.loggedIn.asObservable();
  changeAuthStatus(value: boolean) {
    this.loggedIn.next(value)
  }
  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) { }

  authCheck() {
    return this.http.get(`${this.baseUrl}me`);
  }
  login(loginData: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    };
    return this.http.post(`${this.baseUrl}login`, loginData, httpOptions);
  }
  logout() {
    return this.http.get(`${this.baseUrl}logout`);
  }


}
