import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import {
	MatAutocompleteModule,
	MatButtonModule,
	MatButtonToggleModule,
	MatCardModule,
	MatCheckboxModule,
	MatChipsModule,
	MatDialogModule,
	MatExpansionModule,
	MatGridListModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatMenuModule,
	MatNativeDateModule,
	MatPaginatorModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatRippleModule,
	MatSelectModule,
	MatSidenavModule,
	MatSliderModule,
	MatSlideToggleModule,
	MatSnackBarModule,
	MatSortModule,
	MatTableModule,
	MatTabsModule,
	MatToolbarModule,
	MatTooltipModule,
	MatStepperModule,
} from '@angular/material';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';

import { AppRoutes } from './app.routing';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoginComponent } from './auth/login/login.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';


@NgModule({
	exports: [
		NgxMaterialTimepickerModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatStepperModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		HttpClientModule,
		MatFormFieldModule
	],
	declarations: [],
})


export class MaterialModule { }

@NgModule({
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		FormsModule,
		RouterModule.forRoot(AppRoutes, {
			useHash: true
		}),
		MaterialModule,
		MatNativeDateModule,
		SidebarModule,
		NavbarModule,
		ReactiveFormsModule 
	],
	declarations: [
		AppComponent,
		LoginComponent,
		AdminLayoutComponent,
	],
	 
	providers: [DatePipe],
	bootstrap: [AppComponent]
})
export class AppModule { }
