import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { LoginComponent } from './auth/login/login.component';
import { BeforeLoginService } from './shared/services/before-login.service';
import { AfterLoginService } from './shared/services/after-login.service';


export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [BeforeLoginService]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AfterLoginService],
        children: [{
            path: '',
            loadChildren: './layouts/admin/admin-layout.module#AdminLayoutModule'
        }]
    }


];
