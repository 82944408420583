import { Component, OnInit } from '@angular/core'; 
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TokenService } from 'src/app/shared/services/token.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loggedIn : boolean;
    public loginForm: FormGroup;
    public form = {
      email: null,
      password: null,
    };
    preloader = false;
    
    
    constructor(
        private authService: AuthService,
        private tokenService: TokenService,
        private router: Router,
    ) {
       
        this.loginForm = new FormGroup({
            email: new FormControl('', [
                Validators.required,
                Validators.email,
            ]),
            password: new FormControl('', [
                Validators.required,
                Validators.minLength(6)
            ])
        });
    }

    ngOnInit() {
        this.authService.authStatus.subscribe(value => this.loggedIn = value);
        if (this.loggedIn === true) {
            this.router.navigateByUrl('/dashboard');
        }
    }
    public login = (loginFormValue) => {
        this.preloader = true;
        this.authService.login(loginFormValue).subscribe(
            res => this.handleResponse(res),
            error => this.handleError(error)
        );
    }

   
    public error = null;

    handleResponse(data) {
        localStorage.setItem('user_info', JSON.stringify(data.user));
        this.tokenService.handle(data.access_token);
        this.authService.changeAuthStatus(true);
        this.router.navigateByUrl('/dashboard');
        this.preloader = false;
    }

    handleError(error) { 
        this.preloader = false;
        this.error = error.error.error
    }

    public hasError = (controlName: string, errorName: string) => {
        return this.loginForm.controls[controlName].hasError(errorName);
    }
}
