import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class BeforeLoginService {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.tokenService.loggedIn())  {
      return true;
    }
  }

  constructor(private tokenService: TokenService) { }
}
